.datepicker {
  :global {
    .react-datepicker__month {
      @apply border-b-2 py-2;
    }

    .react-datepicker__week {
      @apply flex justify-around;
    }

    .react-datepicker__day-names {
      @apply flex justify-around text-base text-center border-t-2 border-b-2;
    }

    .react-datepicker__day-name {
      @apply py-2;
    }

    .react-datepicker__day {
      @apply w-12 h-12 flex items-center justify-center text-base transition rounded-full cursor-pointer;
    }

    .react-datepicker__day--selected {
      @apply bg-supertakst-green-300/20 border border-supertakst-green-300;
    }

    .react-datepicker__day--outside-month {
      @apply text-supertakst-gray-900;
    }
    .react-datepicker__today-button {
      @apply inline-block font-medium border p-4 m-4 rounded cursor-pointer text-base;
    }
  }
}
