.iconContainer {
  background-color: #F6F9FB;
  @apply p-8;
  @apply rounded-full;
}

.typePicker {
  input {
    @apply hidden;
    & + .iconContainer {
      @apply cursor-pointer;
    }
    &:checked + .iconContainer {
      @apply bg-gray-200;
    }
  }
}
